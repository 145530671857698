import React from 'react'
import Modal from 'react-modal'
import ArticleHeader from 'components/ArticleHeader'
import LinkToIndex from 'components/atoms/LinkToIndex'
import InnerHtml from 'components/atoms/InnerHtml'
import { getImageUrl, getAltText, stripOfficial } from 'src/commonFunctions'
import CloseBtn from 'img/button_close.png'
import uuid from 'uuid/v4'
import EmbedContainer from 'react-oembed-container'
import { intl } from 'src/constants'
import Credits from 'components/Credits'
import ShareContent from 'components/ShareContent'

export default class InterviewArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      modalContent: null,
      modalData: [],
    }
  }
  handleModalOpen = (event, id) => {
    this.setState({
      isModalOpen: true,
      modalContent: this.state.modalData[id],
    })
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false })
  }

  componentDidMount() {
    Modal.setAppElement('#interview_root')
    this.props.interviewContents.block.forEach(node => {
      if (node.iscolumn === 'true') {
        this.state.modalData[node.head] = (
          <Column
            head={node.head}
            content={node.content}
            handleModalClose={this.handleModalClose}
          />
        )
      }
    })
  }

  render() {
    return (
      <article className="interview_article" id="interview_root">
        <ArticleHeader
          date={this.props.date}
          isOfficial={stripOfficial(this.props.isOfficial)}
          linktoindex={this.props.linktoindex}
          genre={this.props.genre}
        />
        <div className="header">
          <h2>{this.props.title}</h2>
          <ShareContent
            title={this.props.title}
            sharePath={this.props.sharePath}
          />
          <p className="about">{this.props.ogp.description}</p>
          <div className="top_image">
            <img
              lazyload
              src={getImageUrl(this.props.ogp)}
              alt={getAltText(this.props.ogp)}
            />
          </div>
          <LinkToIndex link={this.props.linktoindex} />
          <InnerHtml
            className="leading"
            html={this.props.interviewContents.leading}
          />
        </div>
        {this.props.interviewContents.block.map(node =>
          node.iscolumn == 'false' ? (
            <div key={node.head}>
              <h3 className="head">{node.head}</h3>
              <EmbedContainer markup={node.content}>
                <InnerHtml className="block" html={node.content} />
              </EmbedContainer>
            </div>
          ) : (
            <div
              className="column_switch"
              key={uuid()}
              onClick={() => this.handleModalOpen(event, node.head)}
            >
              <h3 className="head">{node.head}</h3>
            </div>
          )
        )}
        <p>
          <small className="end">
            {this.props.title} {intl.getMessage('end')}
          </small>
        </p>
        <Modal
          className="modal_column"
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
          overlayClassName="overlay"
        >
          {this.state.modalContent}
        </Modal>
        <Credits
          type="interviewBy"
          slug={this.props.slug}
          context={this.props.context}
        />
      </article>
    )
  }
}

const Column = ({ handleModalClose, head, content }) => (
  <div key={head} className="column_content">
    <div className="head" onClick={handleModalClose}>
      <h3>{head}</h3>
    </div>
    <EmbedContainer markup={content}>
      <InnerHtml className="block" html={content} />
    </EmbedContainer>

    <p>
      <small className="end">
        {head} {intl.getMessage('end')}
      </small>
    </p>
    <button onClick={handleModalClose}>
      <img lazyload src={CloseBtn} alt={intl.getMessage('close')} />
    </button>
  </div>
)
