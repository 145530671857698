import React from 'react'
import EmbedContainer from 'react-oembed-container'
import ArticleHeader from 'components/ArticleHeader'
import LinkToIndex from 'components/atoms/LinkToIndex'
import InnerHtml from 'components/atoms/InnerHtml'
import ShareContent from 'components/ShareContent'
import Credits from 'components/Credits'
import { getImageUrl, getAltText, stripOfficial } from 'src/commonFunctions'
import LightboxModal from 'components/LightboxModal'

export default ({
  title,
  linktoindex,
  date,
  contents,
  tags,
  slug,
  ogp,
  genre,
  isOfficial,
  sharePath,
  context,
}) => (
  <article className="post_article">
    <ArticleHeader
      date={date}
      isOfficial={stripOfficial(isOfficial)}
      linktoindex={linktoindex}
      genre={genre}
    />
    <div className="header">
      <h2>{title}</h2>
      <ShareContent title={title} sharePath={sharePath} />
      <p className="about">{ogp.description}</p>
      <div className="top_image">
        <LightboxModal srcUrl={getImageUrl(ogp)} alt={getAltText(ogp)} />
      </div>
    </div>
    <LinkToIndex link={linktoindex} />
    <EmbedContainer markup={contents}>
      <section className="content">
        <InnerHtml html={contents} />
      </section>
    </EmbedContainer>
    <Credits type="postBy" slug={slug} context={context}></Credits>
  </article>
)
