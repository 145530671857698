import React from 'react'
import Helmet from 'react-helmet'
import Modal from 'react-modal'
import { SITE_TITLE, MENUS } from 'src/constants'
import graphqlClient from 'src/graphqlClient'
import {
  getPostQuery,
  getInterviewQuery,
  getNovelQuery,
  getComicQuery,
} from 'src/queries'
import {
  getQueryParams,
  stripLinkToIndex,
  convertArticleType2menus,
} from 'src/commonFunctions'
import { ARTICLE_TYPE, createArticleBaseInfo } from 'src/statics'
import ArticleLayout from 'components/ArticleLayout'
import PostArticle from 'components/PostArticle'
import InterviewArticle from 'components/InterviewArticle'
import ComicArticle from 'components/ComicArticle'
import NovelArticle from 'components/NovelArticle'
import Loading from 'components/atoms/Loading'

const headers = {
  headers: {
    draft: '',
  },
}

// このページは
// /draft?type=post&slug=slugの時のみ開ける
export default class draftTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: undefined,
      type: '',
      renderContents: undefined,
    }
  }

  componentDidMount() {
    Modal.setAppElement('#draft_root')
    this.setState({ isModalOpen: true })
    this.requestDraftPage()
  }

  requestDraftPage() {
    const { adbanners } = this.props.pageContext
    const { slug, type } = getQueryParams(this.props.location.search)

    const process = this.choiceProcess(type)
    if (process) {
      const query = process.query(slug)
      graphqlClient.query({ query, context: headers }).then(result => {
        const article = result.data[process.dataKey]
        const data = {
          ...article,
          articleBaseInfo: createArticleBaseInfo(article),
          adbanners,
          recommendArticles: {
            nextArticle: undefined,
            sameTypeLatestArticles: [],
            latestArticles: [],
          },
        }
        this.setState({ data, type, renderContents: process.render })
      })
    }
  }

  render() {
    return (
      <div id="draft_root">
        {this.state.data !== undefined ? (
          this.state.renderContents(this.state.data)
        ) : (
          <div>
            {/*this.requestDraftPage()*/}
            {/* {this.renderAuthModal()}
            <Loading /> */}
          </div>
        )}
      </div>
    )
  }

  renderAuthModal = () => (
    <Modal
      className="modal_column draft_auth_modal"
      isOpen={this.state.isModalOpen}
    >
      <p>Authentication</p>
      <div className="form">
        <input
          type="text"
          placeholder="ID"
          onChange={event => {
            this.setState({ id: event.target.value })
          }}
        ></input>
        <input
          type="password"
          placeholder="PW"
          onChange={event => {
            this.setState({ pw: event.target.value })
          }}
        ></input>
      </div>
      <div>
        <button onClick={() => this.submit()}>Submit</button>
      </div>
    </Modal>
  )

  submit() {
    const { id, pw } = this.state
    const data = { id, pw }
    auth(__AUTH_URL___, data)
      .then(_data => {
        this.setState({ isModalOpen: false })
        this.requestDraftPage()
      })
      .catch(_error => alert('Wrong id or password, please try again.'))
  }

  renderByPostArticle(context) {
    const {
      type,
      articleBaseInfo,
      sharePath,
      linkToIndex,
      contents,
      adbanners,
      recommendArticles,
    } = context
    const {
      title,
      date,
      slug,
      categories,
      tags,
      ogpSettings,
      isOfficial,
    } = articleBaseInfo

    const linktoindex = stripLinkToIndex(linkToIndex)
    return (
      <>
        <Helmet title={`${title} | ${SITE_TITLE}`} />
        <ArticleLayout
          type={type}
          articleBaseInfo={articleBaseInfo}
          sharePath={sharePath}
          linkToIndex={linkToIndex}
          adbanners={adbanners}
          recommendArticles={recommendArticles}
        >
          <PostArticle
            genre={convertArticleType2menus(type)}
            title={title}
            date={date}
            categories={categories}
            tags={tags}
            linktoindex={linktoindex}
            slug={slug}
            ogp={ogpSettings.ogp}
            contents={contents.texts}
            isOfficial={isOfficial}
            sharePath={sharePath}
            context={headers}
          />
        </ArticleLayout>
      </>
    )
  }

  renderByInterviewArticle(context) {
    const {
      type,
      articleBaseInfo,
      interviewContents,
      sharePath,
      linkToIndex,
      adbanners,
      recommendArticles,
    } = context
    const {
      title,
      date,
      slug,
      categories,
      tags,
      ogpSettings,
      isOfficial,
    } = articleBaseInfo
    const linktoindex = stripLinkToIndex(linkToIndex)

    return (
      <>
        <Helmet title={`${title} | ${SITE_TITLE}`} />
        <ArticleLayout
          type={type}
          articleBaseInfo={articleBaseInfo}
          sharePath={sharePath}
          linkToIndex={linkToIndex}
          adbanners={adbanners}
          recommendArticles={recommendArticles}
        >
          <InterviewArticle
            genre={convertArticleType2menus(type)}
            title={title}
            date={date}
            categories={categories}
            tags={tags}
            linktoindex={linktoindex}
            slug={slug}
            ogp={ogpSettings.ogp}
            interviewContents={interviewContents}
            isOfficial={isOfficial}
            sharePath={sharePath}
            context={headers}
          />
        </ArticleLayout>
      </>
    )
  }

  renderByNovelArticle(context) {
    const {
      type,
      articleBaseInfo,
      novelContent,
      novelsWithImages,
      sharePath,
      linkToIndex,
      adbanners,
      recommendArticles,
    } = context
    const { title, date, slug, tags, ogpSettings, isOfficial } = articleBaseInfo
    const linktoindex = stripLinkToIndex(linkToIndex)

    return (
      <>
        <Helmet title={`${title} | ${SITE_TITLE}`} />
        <ArticleLayout
          type={type}
          articleBaseInfo={articleBaseInfo}
          sharePath={sharePath}
          linkToIndex={linkToIndex}
          adbanners={adbanners}
          recommendArticles={recommendArticles}
        >
          <NovelArticle
            genre={MENUS.NOVEL}
            title={title}
            tags={tags}
            date={date}
            ogp={ogpSettings.ogp}
            linktoindex={linktoindex}
            slug={slug}
            novelContent={novelContent}
            novelsWithImages={novelsWithImages}
            isOfficial={isOfficial}
            sharePath={sharePath}
            context={headers}
          />
        </ArticleLayout>
      </>
    )
  }

  renderByComicArticle(context) {
    const {
      type,
      articleBaseInfo,
      comicContents,
      sharePath,
      linkToIndex,
      adbanners,
      recommendArticles,
    } = context
    const {
      title,
      date,
      slug,
      categories,
      tags,
      ogpSettings,
      isOfficial,
    } = articleBaseInfo
    const linktoindex = stripLinkToIndex(linkToIndex)
    return (
      <>
        <Helmet title={`${title} | ${SITE_TITLE}`} />
        <ArticleLayout
          type={type}
          articleBaseInfo={articleBaseInfo}
          sharePath={sharePath}
          linkToIndex={linkToIndex}
          adbanners={adbanners}
          recommendArticles={recommendArticles}
        >
          <ComicArticle
            genre={MENUS.COMIC}
            title={title}
            date={date}
            categories={categories}
            tags={tags}
            linktoindex={linktoindex}
            slug={slug}
            ogp={ogpSettings.ogp}
            comicContents={comicContents}
            isOfficial={isOfficial}
            sharePath={sharePath}
            context={headers}
          />
        </ArticleLayout>
      </>
    )
  }

  choiceProcess(type) {
    const processes = [
      {
        type: ARTICLE_TYPE.POST,
        query: getPostQuery,
        dataKey: 'postBy',
        render: this.renderByPostArticle,
      },
      {
        type: ARTICLE_TYPE.INTERVIEW,
        query: getInterviewQuery,
        dataKey: 'interviewBy',
        render: this.renderByInterviewArticle,
      },
      {
        type: ARTICLE_TYPE.NOVEL,
        query: getNovelQuery,
        dataKey: 'novelBy',
        render: this.renderByNovelArticle,
      },
      {
        type: ARTICLE_TYPE.COMIC,
        query: getComicQuery,
        dataKey: 'comicBy',
        render: this.renderByComicArticle,
      },
    ]
    return processes.find(val => val.type === type)
  }
}

const auth = (url = ``, data = {}) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  }).then(response => response.json())
}
