import React from 'react'
import reimu from 'img/loading_reimu.gif'
import marisa from 'img/loading_marisa.gif'

export default () => {
  const seed = Math.random()
  if (seed < 0.45) {
    return <img className="loading_image" src={reimu} alt="loading" />
  } else if (seed < 0.9) {
    return <img className="loading_image" src={marisa} alt="loading" />
  }
  return (
    <div className="loading_container">
      <img className="loading_image" src={reimu} alt="loading" />
      <img className="loading_image" src={marisa} alt="loading" />
    </div>
  )
}
