import React from 'react'
import ArticleHeader from 'components/ArticleHeader'
import ArticleCover from 'components/ArticleCover'
import FreeEntry from 'components/FreeEntry'
import LinkToIndex from 'components/atoms/LinkToIndex'
import { stripOfficial } from 'src/commonFunctions'
import Credits from './Credits'
import LightboxModal from 'components/LightboxModal'

export default ({
  title,
  date,
  comicContents,
  isOfficial,
  slug,
  linktoindex,
  genre,
  sharePath,
  context,
}) => (
  <div className="comic_article">
    <ArticleHeader
      date={date}
      isOfficial={stripOfficial(isOfficial)}
      linktoindex={linktoindex}
      genre={genre}
    />
    <ArticleCover
      backgroundStyle={{
        backgroundImage: `url(${comicContents.cover.sourceUrl})`,
      }}
      title={linktoindex ? linktoindex.title : title}
      authors={comicContents.authors}
      chapter={linktoindex ? title : null}
      isComic
      linkToComicWalker={comicContents.viewerurl}
      linkToNiconico={comicContents.niconicourl}
      sharePath={sharePath}
    />
    <FreeEntry freeEntry={comicContents.freeentry} />
    <LinkToIndex link={linktoindex} />
    <div style={{ flexDirection: 'column' }}>
      {comicContents.pages.map(node => (
        <LightboxModal key={node.page.id} srcUrl={node.page.sourceUrl} />
      ))}
    </div>
    <Credits type="comicBy" slug={slug} context={context}></Credits>
  </div>
)
