import React from 'react'
import ArticleHeader from 'components/ArticleHeader'
import { stripOfficial } from 'src/commonFunctions'
import ArticleCover from 'components/ArticleCover'
import FreeEntry from 'components/FreeEntry'
import InnerHtml from 'components/atoms/InnerHtml'
import LinkToIndex from 'components/atoms/LinkToIndex'
import IllustGallery from 'components/IllustGallery'
import EmbedContainer from 'react-oembed-container'
import Credits from './Credits'

export default ({
  title,
  date,
  novelContent,
  linktoindex,
  genre,
  novelsWithImages,
  slug,
  isOfficial,
  sharePath,
  context,
}) => (
  <article className="novel_article">
    <ArticleHeader
      date={date}
      isOfficial={stripOfficial(isOfficial)}
      linktoindex={linktoindex}
      genre={genre}
    />
    <ArticleCover
      backgroundStyle={{
        backgroundImage: `url(${novelContent.cover.sourceUrl})`,
      }}
      title={linktoindex ? linktoindex.title : title}
      authors={novelContent.authors}
      chapter={linktoindex ? title : null}
      sharePath={sharePath}
    />
    <FreeEntry freeEntry={novelContent.freeentry} />
    <LinkToIndex link={linktoindex} />
    <EmbedContainer markup={novelContent.contents}>
      <InnerHtml className="honbun" html={novelContent.contents} />
    </EmbedContainer>
    <IllustGallery novels={changeAry(novelsWithImages)} />
    <Credits type="novelBy" slug={slug} context={context}></Credits>
  </article>
)

const changeAry = novelsWithImages =>
  novelsWithImages ? [novelsWithImages] : []
