import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'

const SP_WIDTH = 480

const LightboxModal = ({ srcUrl, alt }) => {
  const [isScreenSp, setIsScreenSp] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)

  const windowResize = () => setIsScreenSp(window.innerWidth < SP_WIDTH)
  useEffect(() => {
    window.addEventListener('resize', windowResize)

    return () => window.removeEventListener('resize', windowResize)
  })
  return (
    <>
      {isScreenSp ? (
        <img lazyload src={srcUrl} alt={alt} />
      ) : (
        <>
          <img
            lazyload
            src={srcUrl}
            onClick={() => setIsOpen(!modalIsOpen)}
            alt={alt}
          />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(!modalIsOpen)}
            className="modalSearchWindow"
            overlayClassName="modalSearchOverlay lightbox"
          >
            <img lazyload src={srcUrl} alt={alt} />
          </Modal>
        </>
      )}
    </>
  )
}

export default LightboxModal
