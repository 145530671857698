import React from 'react'
import EmbedContainer from 'react-oembed-container'
import InnerHtml from 'components/atoms/InnerHtml'

export default ({ freeEntry }) => (
  <section className="free_entry">
    {freeEntry &&
      freeEntry.length > 0 &&
      freeEntry.map(entry => (
        <article className="entry">
          <h3>{entry.subject}</h3>
          <EmbedContainer markup={entry.contents}>
            <InnerHtml html={entry.contents} />
          </EmbedContainer>
        </article>
      ))}
  </section>
)
